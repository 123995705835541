<template>
  <div class="register-pages">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'RegisterPage',
  mounted() {
    this.checkProfile();
  },
  methods: {
    checkProfile() {
      if (!this.$store.state.auth.userProfile) {
        this.$MS_SOCO_PUBLIC_API_URL.get(`/user/me`).then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            this.$store.dispatch('setProfile', data);
          }
        });
      } else {
        this.$router.push('/register');
      }
    },
  },
};
</script>
